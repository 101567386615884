import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RouteConstant } from '../_constants/route.constant';
import { MainService } from '../_services/main.service';
import { LocalStorageProvider } from '../_services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  appConst = {
    customers: 'customers',
    dashboard: 'dashboard',
    employee: 'employees',
    help_support: 'helpSupport',
    items: 'items',
    jobs: 'jobs',
    messages: 'messages',
    planning: 'planning',
    reporting: 'reporting',
    role: 'roles',
    scripting: 'scripting',
    settings: 'settings',
    about: 'about',
    archive: 'archive',
    coaching: 'coaching',
    gallery: 'gallery',
    descriptions: 'descriptions',
    opportunities: 'opportunities',
    reports: 'reports',
    campaign: 'campaign',
    templates: 'templates',
    invoice: 'invoice',
  }
  roleConst = {
    '/dashboard/report': 'dashboardReportingView',
    '/dashboard/notifications': 'dashboardNotificationView',
    '/dashboard/tasks': 'dashboardTaskView',
    '/dashboard/myevents': 'dashboardMyEventView',
    '/dashboard/events': 'dashboardEventView',
    '/dashboard/opportunities': 'dashboardOpportunityView',
    '/dashboard/job-overview': 'dashboardJobView',
    '/dashboard/customer-overview': 'dashboardCustomerView',
    '/dashboard/employee-overview': 'dashboardEmployeeView',
    '/scripting/publish': 'publishedView',
    '/scripting/publish/script': 'publishedView',
    '/scripting/builder': 'builderView',
    '/scripting/builder/builderpage': 'builderView',
    '/opportunities': 'opportunityView',

    '/opportunities/gallery': 'opportunityView',
    '/opportunities/gallery-detail': 'opportunityView',

    '/opportunities/opportunityDetail': 'opportunityView',
    '/opportunities/add-forms':'opportunityView',
    '/opportunities/opportunityDetail/add-estimate': 'opportunityView',
    '/opportunities/opportunityDetail/review-estimate': 'opportunityView',
    '/opportunities/formsOpp': 'opportunityView',
    '/planning/aimr': 'aimrView',
    '/planning/schedule': 'scheduleView',
    '/planning/calendar': 'calendarView',
    '/planning/dispatch': 'dispatchView',
    
    '/jobs': 'jobView',
    '/jobs/jobDetail': 'jobView',
    '/jobs/jobDetail/add-estimate': 'jobView',
    '/jobs/jobDetail/review-estimate': 'jobView',
    '/jobs/add-forms':'jobView',
    '/jobs/gallery-detail': 'jobView',
    '/jobs/gallery': 'jobview',

    '/customers/list': 'customerView',
    '/customers/list/customer-detail': 'customerView',
    '/employee/list': 'employeeView',
    '/employee/list/employee-detail': 'employeeView',
    '/messages/customer-chat': 'customerChatView',
    '/messages/team-chat': 'teamChatView',
    '/coaching/feed': 'feedView',
    '/coaching/client-feed': 'clientFeedView',
    '/coaching/client-feed/group': 'clientFeedView',
    '/coaching/client-feed/list': 'clientFeedView',
    '/coaching/client-feed/upload-video': 'clientFeedView',
    '/coaching/client-feed/upload-image': 'clientFeedView',
    '/coaching/client-feed/upload-text': 'clientFeedView',
    '/coaching/library': 'libraryView',
    '/coaching/library/video-list': 'libraryView',
    '/coaching/library/video-detail': 'libraryView',
    '/coaching/library/saved-videos': 'libraryView',
    '/coaching/live-stream': 'liveStreamView',
    '/coaching/live-stream/join-meeting': 'liveStreamView',
    '/items/catalog': 'itemView',
    '/items/bundle': 'itemView',
    '/role': 'roleView',
    '/role/add-role': 'roleAdd',
    '/role/edit-role': 'roleEdit',
    '/settings/workingHour': 'workingHourView',
    'settings/employeeDistance': 'employeeDistanceView',
    '/settings/source': 'sourceView',
    '/settings/tax': 'taxView',
    '/settings/setNotification': 'notificationsView',
    '/settings/goal': 'goalsView',
    '/reports/company-sales': 'reportView',
    '/reports/sales-production': 'reportView',
    '/reports/sales-person': 'reportView',
    '/dashboard/view-events': 'dashboardMyEventView',
    '/dashboard/view-event': 'dashboardEventView',
    '/marketing/campaign': 'campaignView',
    '/marketing/templates': 'campaignView',
    '/marketing/blocks': 'campaignView',
    '/invoice': 'invoiceView',
    '/invoice/invoiceDetail': '',
    '/settings/quickBook': '',
    '/settings/quickBookLogs': '',
    '/settings/payment-processing': '',
    '/help_support/support-tickets': 'helpSupportView',
    '/help_support/create-tickets': 'helpSupportView',
    '/help_support/edit-ticket': 'helpSupportView',
    '/about': 'aboutView',
    '/archive/archive-customer': 'archiveCustomerView',
    '/archive/archive-employee': 'archiveEmployeeView',
    '/settings/tags': '',
  }
  key: any;
  constructor(private router: Router, private service: MainService, private storageService: LocalStorageProvider) {
  }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    this.key = state.url.split('/')[1];

    let cUser = this.storageService.getItem('CurrentUser');

    if (cUser && cUser?.roleId) {

      if (cUser && cUser.role_data && cUser.role_data.length) {
        const roleData = cUser.role_data[0];
        if (this.service.isLoggedIn && (this.key === 'my-profile' || this.key == 'about' ? true : roleData[this.roleConst[state.url.split('?')[0]]])) {
          return true;
        }
        else if (state.url === `${RouteConstant.report.url}`) {
          console.log('yes')
          if (roleData[this.roleConst[`${RouteConstant.report.url}`]]) {
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.notification.url}`]]) {
            this.router.navigate([`${RouteConstant.notification.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.tasks.url}`]]) {
            this.router.navigate([`${RouteConstant.tasks.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.myevent.url}`]]) {
            this.router.navigate([`${RouteConstant.myevent.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.event.url}`]]) {
            this.router.navigate([`${RouteConstant.event.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.opportunities.url}`]]) {
            this.router.navigate([`${RouteConstant.opportunities.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.dashboardJob.url}`]]) {
            this.router.navigate([`${RouteConstant.dashboardJob.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.dashboardCustomer.url}`]]) {
            this.router.navigate([`${RouteConstant.dashboardCustomer.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.dashboardEmployee.url}`]]) {
            this.router.navigate([`${RouteConstant.dashboardEmployee.url}`])
            return true;
          }
        }
        else if (state.url == `${RouteConstant.publish.url}`) {
          if (roleData[this.roleConst[`${RouteConstant.publish.url}`]]) {
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.builder.url}`]]) {
            this.router.navigate([`${RouteConstant.builder.url}`])
            return true;
          }
        }
        else if (state.url == `${RouteConstant.planning.url + RouteConstant.aimr.url}`) {
          if (roleData[this.roleConst[`${RouteConstant.planning.url + RouteConstant.aimr.url}`]]) {
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.planning.url + RouteConstant.schedule.url}`]]) {
            this.router.navigate([`${RouteConstant.planning.url + RouteConstant.schedule.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.planning.url + RouteConstant.calendar.url}`]]) {
            this.router.navigate([`${RouteConstant.planning.url + RouteConstant.calendar.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.planning.url + RouteConstant.dispatch.url}`]]) {
            this.router.navigate([`${RouteConstant.planning.url + RouteConstant.dispatch.url}`])
            return true;
          }
        }
        else if (state.url == `${RouteConstant.customerChat.url}`) {
          if (roleData[this.roleConst[`${RouteConstant.customerChat.url}`]]) {
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.teamChat.url}`]]) {
            this.router.navigate([`${RouteConstant.teamChat.url}`])
            return true;
          }
        }
        else if (state.url == `${RouteConstant.liveStream.url}`) {
          if (roleData[this.roleConst[`${RouteConstant.liveStream.url}`]]) {
            this.router.navigate([`${RouteConstant.liveStream.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.feed.url}`]]) {
            this.router.navigate([`${RouteConstant.feed.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.library.url}`]]) {
            this.router.navigate([`${RouteConstant.library.url}`])
            return true;
          }
        }
        else if (state.url == `${RouteConstant.emailMarketing.url}`) {
          if (roleData[this.roleConst[`${RouteConstant.campaign.url}`]]) {
            this.router.navigate([`${RouteConstant.campaign.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.templates.url}`]]) {
            this.router.navigate([`${RouteConstant.templates.url}`])
            return true;
          } else if (roleData[this.roleConst[`${RouteConstant.blocks.url}`]]) {
            this.router.navigate([`${RouteConstant.blocks.url}`])
            return true;
          }
        }
        else if (state.url === `${RouteConstant.reports.url}`) {
          return true;
          // if (roleData[this.roleConst[`${RouteConstant.report.url}`]]){
          //   return true;
          // }else if (roleData[this.roleConst[`${RouteConstant.companySales.url}`]]){
          //   this.router.navigate([`${RouteConstant.companySales.url}`])
          //   return true;
          // }
        }
        this.storageService.clearAll();
        this.router.navigate(['/'])
        return false;
      } else {
        this.storageService.clearAll()
        this.router.navigate(['/'])
        return false;
      }
    }
    else {
      if (cUser && cUser.permission_data && cUser.permission_data.length) {
        const permissions = cUser.permission_data[0];
        if (this.service.isLoggedIn && (['my-profile', 'reports'].includes(this.key) ? true : permissions[this.appConst[this.key]])) {
          return true;
        }
        this.storageService.clearAll()
        this.router.navigate(['/'])
        return false;
      }
      else {
        this.storageService.clearAll()
        this.router.navigate(['/'])
        return false;
      }
    }
  }

}



@Injectable()
export class CanLoginActivate implements CanActivate {
  constructor(private router: Router, private service: MainService, private storageService: LocalStorageProvider) { }

  canActivate() {

    if (!this.service.isLoggedIn) {
      return true;
    } else {
      let cUser = this.storageService.getItem('CurrentUser');
      if (cUser && cUser.permission_data && cUser.permission_data.length) {
        const permissionData = cUser.permission_data[0];
        if (permissionData?.dashboard) {
          this.router.navigateByUrl(`/${RouteConstant.dashboard.path}`);
        } else if (permissionData?.scripting) {
          this.router.navigateByUrl(`${RouteConstant.publish.url}`);
        } else {
          this.router.navigateByUrl(`${RouteConstant.planning.url + RouteConstant.aimr.url}`);
        }
        return false;
      }
      else {
        this.storageService.clearAll();
        return true;
      }
    }
  }
}
