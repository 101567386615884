export const environment = {
  production: true,
  apiUrl: 'https://devadmin.servicegalaxy.com:3001/',
  // apiUrl: 'http://10.10.15.8:3001/',

  // ngrok  
  // apiUrl: 'https://9024-49-249-112-98.ngrok-free.app/',


  // apiUrl: 'https://stagingadmin.servicegalaxy.com:3001/',
  bucketurl: 'https://service-galaxy-dev.s3.us-east-2.amazonaws.com/',
  baseURL: 'https://devsuperadmin.servicegalaxy.com/#/client/client-profile/',
  // CRYPTO_UNSUBSSCRIBE_SIGNATURE :'qwerdecrypt567865432hgfdsa0987u',
  CRYPTO_UNSUBSSCRIBE_SIGNATURE: 'sdfgencrypt098978878hjjkiuyo7890',
  CRYPTO_MATCH_SIGN: 'qwerdecrypt567865432hgfdsa0987u',
  // QA server
  // apiUrl: 'https://stagingadmin.servicegalaxy.com:3001/',
  // bucketurl: 'https://service-galaxy-dev.s3.us-east-2.amazonaws.com/',
  // baseURL: 'https://stagingsuperadmin.servicegalaxy.com/#/client/client-profile/',

  valorWebHook: 'https://stagingadmin.servicegalaxy.com/v1/client/valorWebhook',
  bucketname: "service-galaxy-dev",
  calKEY: '0285120952-fcs-1681488481',
  googleKey: "AIzaSyBfloiwqQHTVEDRbYfQt_asJQXUMGRRSlc",
  firebase: {
    apiKey: "AIzaSyC2UyybrDbece03B9gLCOc1mFlRIrG7bgk",
    authDomain: "service-galaxy.firebaseapp.com",
    databaseURL: "https://service-galaxy-default-rtdb.firebaseio.com",
    projectId: "service-galaxy",
    storageBucket: "service-galaxy.appspot.com",
    messagingSenderId: "466475764062",
    appId: "1:466475764062:web:1d39f2fccf6fba129467cf",
    measurementId: "G-JVZL9QCTSJ"
  },
  meetingConfig: {
    sdkKey: 'ikI41GHPAriAjMHhDEx9iRYmTeBrWv7UsGGa',
    CLIENT_ID: 'pQu905JvTIORykAIV8HcPw',
    CLIENT_SECRET: '5QLihzRoykfPg4AVKtpykR17hcVKxOdS'
    // sdkKey: 'qVQM62bRqvc002v8dYaf61a4I7XVCdnhP6ic'

  }
};