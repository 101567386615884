export const RouteConstant = {
    login: { path: 'login', url: '/login' },
    unsubscribe: { path: 'unsubscribe', url: '/unsubscribe' },
    forgotPassword: { path: 'forgot-password', url: '/forgot-password' },
    resetPassword: { path: 'reset-password', url: '/reset-password' },
    dashboard: { path: 'dashboard', url: '/dashboard' },
    verifymail: { path: 'verifymail', url: '/verifymail' },
    profile: { path: 'my-profile', url: '/my-profile' },
    changePassword: { path: 'change-password', url: '/change-password' },
    manageSubscription: { path: 'manage-subscription', url: '/manage-subscription' },
    editProfile: { path: 'edit', url: '/edit' },
    tasks: { path: 'tasks', url: '/dashboard/tasks' },
    opportunities: { path: 'opportunities', url: '/dashboard/opportunities' },
    opportunityDetail: { path: 'opportunityDetail', url: '/opportunities/opportunityDetail' },
    opportunityGallery: { path: 'gallery', url: '/opportunities/gallery' },
    opportunityGalleryDetail: { path: 'gallery-detail', url: '/opportunities/gallery-detail' },
    invoiceDetail: { path: 'invoiceDetail', url: '/invoice/invoiceDetail' },
    opportunityDetailForm: { path: 'add-forms', url: '/opportunities/add-forms' },
    jobs: { path: 'jobs', url: '/jobs' },
    jobDetail: { path: 'jobDetail', url: '/jobs/jobDetail' },
    jobDetailForm: { path: 'add-forms', url: '/jobs/add-forms' },
    jobGallery: { path: 'gallery', url: '/jobs/gallery' },
    jobGalleryDetail: { path: 'gallery-detail', url: '/jobs/gallery-detail' },
    employee: { path: 'employee', url: '/employee' },
    report: { path: 'report', url: '/dashboard/report' },
    planning: { path: 'planning', url: '/planning' },
    aimr: { path: 'aimr', url: '/aimr' },
    schedule: { path: 'schedule', url: '/schedule' },
    calendar: { path: 'calendar', url: '/calendar' },
    dispatch: { path: 'dispatch', url: '/dispatch' },
    event: { path: 'events', url: '/dashboard/events' },
    myevent: { path: 'myevents', url: '/dashboard/myevents' },
    notification: { path: 'notifications', url: '/dashboard/notifications' },
    dashboardJob: { path: 'job-overview', url: '/dashboard/job-overview' },
    dashboardCustomer: { path: 'customer-overview', url: '/dashboard/customer-overview' },
    dashboardEmployee: { path: 'employee-overview', url: '/dashboard/employee-overview' },
    scripting: { path: 'scripting', url: '/scripting' },
    publish: { path: 'publish', url: '/scripting/publish' },
    builder: { path: 'builder', url: '/scripting/builder' },
    builderpage: { path: 'builderpage', url: '/scripting/builder/builderpage' },
    script: { path: 'script', url: '/scripting/publish/script' },
    role: { path: 'role', url: '/role' },
    help: { path: 'help_support', url: '/help_support' },
    helpCreate: { path: 'create-tickets', url: '/help_support/create-tickets' },
    editTicket: { path: 'edit-ticket', url: '/help_support/edit-ticket' },
    about: { path: 'about', url: '/about' },
    ticketList: { path: 'support-tickets', url: '/help_support/support-tickets' },
    settings: { path: 'settings', url: '/settings' },
    workingHour: { path: 'workingHour', url: '/settings/workingHour' },
    employeeDistance: { path: 'employeeDistance', url: '/settings/employeeDistance' },
    lookAhead: { path: 'lookAhead', url: '/settings/lookAhead' },
    goal: { path: 'goal', url: '/settings/goal' },
    inspection: { path: 'inspection-status', url: '/settings/inspection-status' },
    setNotification: { path: 'setNotification', url: '/settings/setNotification' },
    editNotification: { path: 'editNotification', url: '/settings/editNotification' },
    emailNotification: { path: 'emailNotification', url: '/settings/emailNotification' },
    quickBook: { path: 'quickBook', url: '/settings/quickBook' },
    quickBookLogs: { path: 'quickBookLogs', url: '/settings/quickBookLogs' },
    paymentProcessing: { path: 'payment-processing', url: '/settings/payment-processing' }, 
    tags: { path: 'tags', url: '/settings/tags' },
    customers: { path: 'customers', url: '/customers' },
    customerDetail: { path: 'customer-detail', url: '/customers/list/customer-detail' },
    employeeDetail: { path: 'employee-detail', url: '/employee/list/employee-detail' },
    archive: { path: 'archive', url: '/archive' },
    archiveCustomer: { path: 'archive-customer', url: '/archive/archive-customer' },
    archiveEmployee: { path: 'archive-employee', url: '/archive/archive-employee' },
    messages: { path: 'messages', url: '/messages' },
    teamChat: { path: 'team-chat', url: '/messages/team-chat' },
    customerChat: { path: 'customer-chat', url: '/messages/customer-chat' },
    coaching: { path: 'coaching', url: 'coaching' },
    feed: { path: 'feed', url: '/coaching/feed' },
    clientFeed: { path: 'client-feed', url: '/coaching/client-feed' },
    clientFeedGroup: { path: 'group', url: '/coaching/client-feed/group' },
    clientFeedList: { path: 'list', url: '/coaching/client-feed/list' },
    uploadVideo: { path: 'upload-video', url: '/coaching/client-feed/upload-video' },
    uploadImage: { path: 'upload-image', url: '/coaching/client-feed/upload-image' },
    uploadText: { path: 'upload-text', url: '/coaching/client-feed/upload-text' },
    library: { path: 'library', url: '/coaching/library' },
    savedVideos: { path: 'saved-videos', url: '/coaching/library/saved-videos' },
    videoList: { path: 'video-list', url: '/coaching/library/video-list' },
    videoDetail: { path: 'video-detail', url: '/coaching/library/video-detail' },
    service: { path: 'services', url: '/items/services' },
    skill: { path: 'skills', url: '/settings/skills' },
    description: { path: 'description', url: '/settings/description' },
    items: { path: 'items', url: 'items' },
    bundle: { path: 'bundle', url: '/items/bundle' },
    catalog: { path: 'catalog', url: '/items/catalog' },
    addRoles: { path: 'add-role', url: '/role/add-role' },
    editRole: { path: 'edit-role', url: '/role/edit-role' },
    liveStream: { path: 'live-stream', url: '/coaching/live-stream' },
    tax: { path: 'tax', url: '/settings/tax' },
    source: { path: 'source', url: '/settings/source' },
    joinMeeting: { path: 'join-meeting', url: '/coaching/live-stream/join-meeting' },
    addEstimate: { path: 'add-estimate', url: '/add-estimate' },
    reviewEstimate: { path: 'review-estimate', url: '/review-estimate' },
    reports: { path: 'reports', url: '/reports' },
    companySales: { path: 'company-sales', url: '/reports/company-sales' },
    salesProduction: { path: 'sales-production', url: '/reports/sales-production' },
    salesPerson: { path: 'sales-person', url: '/reports/sales-person' },
    viewEvents: { path: 'view-events', url: '/dashboard/view-events' },
    viewEvent: { path: 'view-event', url: '/dashboard/view-event' },
    forms: { path: 'forms', url: '/forms' },
    emailMarketing: { path: 'marketing', url: '/marketing' },
    campaign: { path: 'campaign', url: '/marketing/campaign' },
    templates: { path: 'templates', url: '/marketing/templates' },
    addTemplates: { path: 'add-templates', url: '/marketing/add-templates' },
    blocks: { path: 'blocks', url: '/marketing/blocks' },
    addCampaign: { path: 'add-campaign', url: '/marketing/add-campaign' },
    itemList: { path: 'item-list', url: '/marketing/item-list' },
    invoices: { path: 'invoice', url: '/invoice' }



}